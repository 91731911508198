import React from 'react'

const InfoBtnLeft = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
            <g clip-path="url(#clip0_102_92)">
                <path d="M36.4167 21.0833H13.6851L20.6426 12.7267C20.9679 12.3353 21.1244 11.8306 21.0777 11.3238C21.031 10.817 20.7848 10.3495 20.3934 10.0242C20.002 9.69883 19.4974 9.54232 18.9906 9.58904C18.4837 9.63577 18.0162 9.88192 17.6909 10.2733L8.10758 21.7733C8.04311 21.8648 7.98545 21.9609 7.93508 22.0608C7.93508 22.1567 7.93508 22.2142 7.80092 22.31C7.71404 22.5298 7.66855 22.7637 7.66675 23C7.66855 23.2363 7.71404 23.4702 7.80092 23.69C7.80092 23.7858 7.80091 23.8433 7.93508 23.9392C7.98545 24.0391 8.04311 24.1352 8.10758 24.2267L17.6909 35.7267C17.8711 35.943 18.0968 36.117 18.3519 36.2363C18.6069 36.3555 18.8852 36.4171 19.1667 36.4167C19.6146 36.4175 20.0486 36.2616 20.3934 35.9758C20.5875 35.8149 20.7479 35.6173 20.8655 35.3943C20.9831 35.1713 21.0555 34.9273 21.0787 34.6763C21.1018 34.4252 21.0752 34.1721 21.0004 33.9314C20.9256 33.6906 20.804 33.467 20.6426 33.2733L13.6851 24.9167H36.4167C36.9251 24.9167 37.4126 24.7147 37.772 24.3553C38.1315 23.9958 38.3334 23.5083 38.3334 23C38.3334 22.4917 38.1315 22.0042 37.772 21.6447C37.4126 21.2853 36.9251 21.0833 36.4167 21.0833Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_102_92">
                    <rect width="46" height="46" rx="23" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default InfoBtnLeft
