import React, { useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation, useNavigate, useParams } from 'react-router';
import { dataArrayProtectToday } from '../../API/Data';

function ExploringHeroSection1() {
    const { pageName } = useParams();

    const result = dataArrayProtectToday.filter((itemHeading) => (itemHeading.heading === pageName));
    const topImg = result[0].topImg;
    // console.log('result', result[0]);

    // const location = useLocation();
    //   console.log('state',location.state);
    //   console.log('heroImg',location.state?.heroImg)
    //   console.log('topImg',location.state?.topImg)
    //   console.log('title',location.state?.title)
    //   const cardData = location.state?.heroImg

    const navigate = useNavigate();

    const handleClickQuote = () => {
        // route against pageName
        if (pageName === 'Flood') {
            navigate('/flood-home');
        }
        // if (location.state?.title === 'Flood') {
        //     navigate('/flood-home');
        // }
    }

    return (
        <div className="container-insurance  ">
            <div className="background-image">
                <img src={topImg} alt="" />
            </div>
            <div className="content ">
                <div className="heading  txt-justify w-100">
                    <h1 className="txt-justify  w-100 txt-46"> <span className=''>Save up to </span><font color="red">25%</font> <br /> <span className='txt-fw-400 txt-38'>on your Insurance!*</span></h1>
                </div>
            </div>
            <div className="tabs-container-responsive p-0">
                <div className='tabs-container card-radius me-3  box-shadow'>
                    <Tabs
                        transition={false}
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="Tabs exploringTabs mb-3 bkg-red"
                        fill
                    >
                        <Tab className=" Tab ps-4 pe-4" eventKey="home" title={<div className='txt-fw-700 exploring-tab-title'>{pageName}</div>}>
                            <div>
                                <h1 className='txt-fw-700 my-4'>Compare Quotes <br />
                                    all in one place. </h1>
                                <div className="search">
                                    <input type="search" name="" id="" placeholder='  Enter your Zip Code here app' />
                                    <button
                                        className='txt-14'
                                        onClick={handleClickQuote}
                                    >
                                        View my quotes
                                    </button>
                                </div>
                                <p className='text-center mt-2 mb-2'>Or call <span className='txt-secondary'> 201-898-2013</span> for a quote</p>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}

export default ExploringHeroSection1