import React from 'react'
import Button from '../../../components/Button'
function PersonalizedQuotes() {
    return (
        <>
            <div className="container pb-5 ">
                <div className="paper-writing-service d-flex flex-column align-items-center  ">
                    <h1 className=" mt-5 text-center fw-bold">Get personalized quotes in 5 minutes or less.</h1>
                    <div className="container mt-4">
                        <div className="row d-flex justify-content-between align-items-center pb-5">
                            <div className="col-md-6 ">
                                <div className='img-box'>
                                <img  src="./assets/images/quotesgirl.png" alt="" />
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <div className="px-4 mt-4">
                                    <h4 className=' txt-28 txt-fw-700' >Compare personalized  <br />quotes</h4>
                                    <p className=" text-color-secondary py-4 txt-22 txt-fw-500 pe-1 ">Answer a couple of questions, we'll provide  accurate real-time quotes. Users save up to  $996 on annual premiums when they  compare with OQVIC.</p>
                                    {/* <button className="button-primary  button-radius box-shadow px-4 py-2">Get my Quotes</button> */}
                                <Button type="submit" btnType="button-primary">
                                    Get the Quotes
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>
    )
}

export default PersonalizedQuotes