import React from "react";
import BorderText from "../BorderText/border-text";
import { motion } from "framer-motion";
import GetAQoute from "../Button/Get A Qoute/GetAQoute";
const HowItWorks = () => {
  return (
    <section className="py-5">
      <div className="container">
        <div className="row">
          <div div className="col-12">
            <h1 className="text-center">
              How it{" "}
              <span className="textStyle">
                works <BorderText />
              </span>
            </h1>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-md-3">
            <motion.div
              initial={{ opacity: 0, transform: "translateY(10px)" }}
              whileInView={{ opacity: 1, transform: "translateY(0)" }}
              transition={{ delay: 0.5, type: "ease" }}
              className="stepContainer"
            >
              <div className="step">
                <h2>1</h2>
                <img src="/assets/images/arc.svg" alt="image" />
              </div>
              <p>Fill out our online form.</p>
            </motion.div>
          </div>
          <div className="col-md-6">
            <motion.div
              initial={{ opacity: 0, transform: "translateY(10px)" }}
              whileInView={{ opacity: 1, transform: "translateY(0)" }}
              transition={{ delay: 0.7 }}
              className="stepContainer"
            >
              <div className="step">
                <h2>2</h2>
                <img src="/assets/images/arc.svg" alt="image" />
              </div>
              <p>Receive your personalized flood insurance quote.</p>
            </motion.div>
          </div>
          <div className="col-md-3">
            <motion.div
              initial={{ opacity: 0, transform: "translateY(10px)" }}
              whileInView={{ opacity: 1, transform: "translateY(0)" }}
              transition={{ delay: 1 }}
              className="stepContainer"
            >
              <div className="step">
                <h2>3</h2>
                <img src="/assets/images/arc.svg" alt="image" />
              </div>
              <p>Start saving today!</p>
            </motion.div>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">
           <GetAQoute />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
