import React from 'react'

const InfoBtnRight = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" viewBox="0 0 46 46" fill="none">
            <g clip-path="url(#clip0_102_41)">
                <path d="M9.58325 21.0833H32.3149L25.3574 12.7267C25.0321 12.3353 24.8756 11.8306 24.9223 11.3238C24.969 10.817 25.2152 10.3495 25.6066 10.0242C25.998 9.69883 26.5026 9.54232 27.0094 9.58904C27.5163 9.63577 27.9838 9.88192 28.3091 10.2733L37.8924 21.7733C37.9569 21.8648 38.0145 21.9609 38.0649 22.0608C38.0649 22.1567 38.0649 22.2142 38.1991 22.31C38.286 22.5298 38.3314 22.7637 38.3333 23C38.3314 23.2363 38.286 23.4702 38.1991 23.69C38.1991 23.7858 38.1991 23.8433 38.0649 23.9392C38.0145 24.0391 37.9569 24.1352 37.8924 24.2267L28.3091 35.7267C28.1289 35.943 27.9032 36.117 27.6481 36.2363C27.3931 36.3555 27.1148 36.4171 26.8333 36.4167C26.3854 36.4175 25.9514 36.2616 25.6066 35.9758C25.4125 35.8149 25.2521 35.6173 25.1345 35.3943C25.0169 35.1713 24.9445 34.9273 24.9213 34.6763C24.8982 34.4252 24.9248 34.1721 24.9996 33.9314C25.0744 33.6906 25.196 33.467 25.3574 33.2733L32.3149 24.9167H9.58325C9.07492 24.9167 8.58741 24.7147 8.22796 24.3553C7.86852 23.9958 7.66659 23.5083 7.66659 23C7.66659 22.4917 7.86852 22.0042 8.22796 21.6447C8.58741 21.2853 9.07492 21.0833 9.58325 21.0833Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_102_41">
                    <rect width="46" height="46" rx="23" transform="matrix(-1 0 0 1 46 0)" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export default InfoBtnRight
