import React, { useState } from "react";
import { Card } from "react-bootstrap";
// import Card Pages
import GenderInfo from "../CardPages/GenderInfo/GenderInfo";
import AddressMapInfo from "../CardPages/AddressMapInfo/AddressMapInfo";
import ConstructionTypeInfo from "../CardPages/ConstructionTypeInfo/ConstructionTypeInfo";
import OccupancyTypeInfo from "../CardPages/OccupancyTypeInfo/OccupancyTypeInfo";
import FoundationType from "../CardPages/FoundationType/FoundationType";
import BuildingInfo from "../CardPages/BuildingInfo/BuildingInfo";
import FloodInfo from "../CardPages/FloodInfo/FloodInfo";
import BuildingGeneralInfo from "../CardPages/BuildingGeneralInfo/BuildingGeneralInfo";
import PhoneBusinessMailInfo from "../CardPages/PhoneBusinessMailInfo/PhoneBusinessMailInfo";
import Email from "../CardPages/Email/Email";
import Mortage from "../CardPages/Mortage/Mortage";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Spinner from "../../Spinner/Spinner";
const API_URL = "https://api.onio.ai";

const CardMain = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  // Calculate the width dynamically based on currentPage, update the components length accordingly like (currentPage + 1) / componentLength
  const progressBarWidth = ((currentPage + 1) / 10) * 100 + "%";
  const [formData, setFormData] = useState({
    accountType: "Individual",
    email: "",
    fullname: "",
    address: "",
    unit: null,
    constructionType: "Frame",
    occupancyType: "Single Family",
    foundationType: "Basement",
    buildingOverWater: "Yes",
    buildingUnderConstruction: "Yes",
    primaryResidence: "No",
    floodLoss: "Yes",
    previousFlooding: { isTrue: "No", reason: "" },
    noOfFloors: null,
    mortage: { isTrue: "No", detail: "" },
    constructionYear: null,
    sqFoot: null,
    buildCost: null,
    contentCost: null,
    phone: null,
    businessName: "",
    mailAddress1: "",
    mailAddress2: "",
    startDate: "",
  });



  const nextStep = () => {
    setCurrentPage(currentPage + 1);
  };
  const prevStep = () => {
    setCurrentPage(currentPage - 1);
  };
  const onSubmission = async (data) => {
    try {
    setLoading(true)
      const reqData = {
        email: data.email,
        full_name: data.fullname,
        phone: data.phone,
        address: data.address,
        unit: data.unit,
        constructionType: data.constructionType,
        occupancyType: data.occupancyType,
        buildingOverWater: data.buildingOverWater,
        buildingUnderConstruction: data.buildingUnderConstruction,
        primaryResidence: data.primaryResidence,
        floodLoss: data.floodLoss,
        noOfFloors: data.noOfFloors,
        startDate: data.startDate,
        sqFoot: data.sqFoot,
        buildCost: data.buildCost,
        contentCost: data.contentCost,
        businessName: data.businessName,
        mailAddress1: data.mailAddress1,
      };
      const res = await axios.post(
        `${API_URL}/api/v1/crm/oqvic/create-contact?companyId=2`,
        { ...reqData }
      );
      setLoading(false)
       navigate("/Thankyou");
    console.log(res.data)
    } catch (error) {
      throw new Error(error);
    }
  };
  // function for display Card Pages againt page number
  const PageDisplay = () => {
    const components = [
        Email,
        AddressMapInfo,
        ConstructionTypeInfo,
        OccupancyTypeInfo,
        FoundationType,
        BuildingInfo,
        FloodInfo,
        Mortage,
        BuildingGeneralInfo,
        PhoneBusinessMailInfo
      ];
     
      const Component = components[currentPage];
      return (
        <Component
          formData={formData}
          setFormData={setFormData}
          next={nextStep}
          previous={prevStep}
          final={onSubmission}
        />
      );
  };

  return (
    <Card className="cardStyle">
      <div>{PageDisplay()}</div>

      <div
        className="fillingProgressBar"
        style={{ width: progressBarWidth }}
      ></div>
     {loading &&  <Spinner />}
    </Card>
  );
};

export default CardMain;
