import React from 'react'
import Hero from '../../../components/Flood-Landing-Page/hero'
import HowItWorks from '../../../components/Flood-Landing-Page/HowItWorks'
import StartSaving from '../../../components/Flood-Landing-Page/StartSaving'
import WhyOcvic from '../../../components/WhyOqvic/WhyOqvic'
import FooterOqvic from '../../../components/FooterOqvic/FooterOqvic'
import HeaderContact from '../../../components/HeaderContact/HeaderContact'
const FloodPage = () => {
  return (
    <>
    <HeaderContact />
    <Hero />
    <HowItWorks />
    <WhyOcvic />
    <StartSaving />
    <FooterOqvic />
    </>
  )
}

export default FloodPage