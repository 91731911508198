const dataArrayProtectToday = [
  {
    id: 1,
    img: "./assets/images/rec1.png",
    heading: "Home",
    topImg:"./assets/images/home1.jpg",
    bottomImg:"./assets/images/secHome.png"
  },
  {
    id: 2,
    img: "./assets/images/rec2.png",
    heading: "Landlord",
    topImg:"./assets/images/home1.jpg",
    bottomImg:"./assets/images/secHome.png"
  },
  {
    id: 3,
    img: "./assets/images/rec3.png",
    heading: "Renters", 
     topImg:"./assets/images/home1.jpg",
    bottomImg:"./assets/images/secHome.png"
  },
  {
    id: 4,
    img: "./assets/images/rec4.png",
    heading: "Vocational Renter",
    topImg:"./assets/images/vacation1.png",
    bottomImg:"./assets/images/vacation2.jpg"
  },
  {
    id: 5,
    img: "./assets/images/rec55.png",
    styleClass: "image-style-2",
    heading: "Flood",
    topImg:"./assets/images/flood1.jpg",
    bottomImg:"./assets/images/flood2.jpg"
  },
  {
    id: 6,
    img: "./assets/images/rec6.png",
    heading: "Auto",
    topImg:"./assets/images/auto1.png",
    bottomImg:"./assets/images/auto2.png"
  },

  {
    id: 7,
    img: "./assets/images/rec77.png",
    styleClass: "image-style-2",
    heading: "Boat",
    topImg:"./assets/images/boat1.png",
    bottomImg:"./assets/images/boat2.png"
  },
  {
    id: 8,
    img: "./assets/images/rec8.png",
    heading: "RV",
    topImg:"./assets/images/rv1.png",
    bottomImg:"./assets/images/rv2.png"
  },
  {
    id: 9,
    img: "./assets/images/rec9.png",
    heading: "General Contractor",
    topImg:"./assets/images/contactor1,png",
    bottomImg:"./assets/images/contactor2.png"
  },
  {
    id: 10,
    img: "./assets/images/rec10.png",
    heading: "Surety Bond",
    topImg:"./assets/images/home1.jpg",
    bottomImg:"./assets/images/secHome.png"
  },
  {
    id: 11,
    img: "./assets/images/rec11.png",
    heading: "Business Owners",
    topImg:"./assets/images/home1.jpg",
    bottomImg:"./assets/images/secHome.png"
  },
  {
    id: 12,
    img: "./assets/images/rec12.png",
    heading: "Business Liability",
    topImg:"./assets/images/business1.png",
    bottomImg:"./assets/images/business2.png"
  },
  // Add more objects as needed
];



const winHandsData = [
  {
    id: 1,
    starImg: "./assets/images/star-fill.svg",
    img: "./assets/images/win1.png",
    heading: "“FUN”",
    content: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text",
    agentName: "Agent: Nate Stainaker"
  },
  {
    id: 2,
    starImg: "./assets/images/star-fill.svg",
    img: "./assets/images/win2.png",
    heading: "“LISTENED”",
    content: " Lorem Ipsum is simply  dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    agentName: "Agent: Scott Stainaker"

  },
  {
    id: 3,
    starImg: "./assets/images/star-fill.svg",
    img: "./assets/images/win3.png",
    heading: "“COMMUNICATION”",
    content: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. Lorem Ipsum is simply dummy",
    agentName: "Agent: Emma Stainaker"
  },
  {
    id: 4,
    starImg: "./assets/images/star-fill.svg",
    img: "./assets/images/win4.png",
    heading: "“AWESOME”",
    content: " Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text. .",
    agentName: "Agent: Emma Stainaker"
  }
];

export { winHandsData, dataArrayProtectToday };