import React, { useState } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

function InsuranceSave() {
  const [image,setImage]=useState("./assets/images/homeInsurance.png")
  
const imgHandlerHome= ()=>{
    setImage("./assets/images/homeInsurance.png")
}
const imgHandlerAuto= ()=>{
    setImage("./assets/images/autotab.png")
}
const imgHandlerBusiness= ()=>{
    setImage("./assets/images/businesstab.jpg")
}
    return (
        <div className="container-insurance  ">
            <div className="background-image">
                <img className='object-fit-cover' src={image} alt="" />
            </div>
            <div className="content ">
                <div className="heading  txt-justify w-100">
                    <h1 className="txt-justify  w-100 txt-46"> <span className=' txt-fw-700'> Save up to </span><font color="red">25%</font> <br /> <span className='txt-fw-500 txt-38'>on your Insurance!*</span></h1>
                </div>
            </div>
            <div className='tabs-container card-radius box-shadow'>
                <Tabs
                    transition={false}
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="Tabs mb-3 "
                    fill
                >
                    <Tab className=" Tab ps-4 pe-4" eventKey="home" title={<div className='tab-title txt-fw-700' onClick={imgHandlerHome}>HOME</div>} >
                        <div>
                            <h1 className='txt-fw-700 my-4'>Compare Quotes <br />
                                all in one place. </h1>
                            <div className="search">
                                <input type="search" name="" id="" placeholder='  Enter your Zip Code here app' />
                                <button className='txt-14'>View my quotes</button>
                            </div>
                            <p className='text-center mt-2 mb-2'>Or call <span className='txt-secondary'> 201-898-2013</span> for a quote</p>
                        </div>
                    </Tab>
                    <Tab className=" Tab ps-4 pe-4" eventKey="profile" title={<div className='tab-title txt-fw-700' onClick={imgHandlerAuto}>AUTO</div>}>
                        <div>
                            <h1 className='txt-fw-700 my-4'>Compare Quotes <br />
                                all in one place. </h1>
                            <div className="search">
                                <input type="search" name="" id="" placeholder='  Enter your Zip Code here app' />
                                <button className='txt-14'>View my quotes</button>
                            </div>
                            <p className='text-center mt-2 mb-2 '>Or call <span className='txt-secondary'> 201-898-2013</span> for a quote</p>
                        </div>
                    </Tab>
                    <Tab className=" Tab ps-4 pe-4" eventKey="contact" title={<div className='tab-title txt-fw-700' onClick={imgHandlerBusiness}>BUSINESS</div>} >
                        <div>
                            <h1 className='txt-fw-700 my-4'>Compare Quotes <br />
                                all in one place. </h1>
                            <div className="search">
                                <input type="search" name="" id="" placeholder='  Enter your Zip Code here app' />
                                <button className='txt-14'>View my quotes</button>
                            </div>
                            <p className='text-center mt-2 mb-2'>Or call <span className='txt-secondary'> 201-898-2013</span> for a quote</p>
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default InsuranceSave