import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Logo/Logo";
function FooterOqvic() {
  return (
    <>
      <div className="container-fluid mt-5 me-0">
        <div className="row d-flex justify-content-center flex-wrap ">
          <div className="col-md-6 col-lg-4 col-xl-4 ps-5 pb-5 ">
            <div className="ps-3">
              <Logo />
              <p className=" txt-14 txt-fw-500 py-3">
                Your information is protected by SSL encryption. By submitting
                this contact request you expressly consent OQVIC may contact
                you by telephone, email, or direct mail even if you have
                previously registered on a Do Not Call registry or have
                previously requested that OQVIC not contact you for
                solicitation purposes.
              </p>
              <p className="txt-fw-500 ">
                <font color="#FF0000">Stay Connected</font>
              </p>
              <div>
                <img
                  className="me-3"
                  src="./assets/images/facebook.svg"
                  alt=""
                />
                <img src="./assets/images/insta.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4 p-5 col-12  ">
            <div className="row mt-5  flex-wrap ps-3">
              <div className=" sample d-flex  flex-wrap ">
                <div className="samp2 me-3">
                  <div>
                    <div className="contact-div me-3">
                      <p className="fw-bold txt-primary txt-16">Contact Us </p>
                      <p className=" txt-14  txt-fw-500">
                        <a
                          href="mailto:  contact@oqvic.com"
                          className="txt-red"
                        >
                          <i class="bi bi-envelope"></i> contact@oqvic.com
                        </a>
                      </p>
                      <p className="txt-14  txt-fw-500">
                       
                        <a href="tel:201-898-2013" className="txt-red"> <i class="bi bi-phone-fill"></i> 201-898-2013</a>
                      </p>
                    </div>
                  </div>{" "}
                </div>
                <div className="samp2">
                  {" "}
                  <div className="d-flex justify-content-end">
                    <div className="contact-div">
                      {" "}
                      <p className="fw-bold txt-primary">Legal </p>
                      <p>
                        {" "}
                        <Link to="/" className="txt-fw-500  txt-primary">
                          Privacy Policy{" "}
                        </Link>
                      </p>
                      <p>
                        {" "}
                        <Link to="/" className="txt-fw-500  txt-primary">
                          Terms & Conditions{" "}
                        </Link>
                      </p>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-4 col-xl-4 col-sm-6  ">
            <div className="  h-100 pb-3  d-flex me-5 justify-content-end  align-items-end ">
              <img
                className="mb-5 button-radius"
                height="250"
                width="350"
                src="./assets/images/mapImg.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterOqvic;
