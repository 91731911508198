import React from 'react'

function Button({type="button",btnType="button-primary",children,...rest}) {
  return (
 
        <button className={`${btnType}  button-radius box-shadow px-4 py-2`} type={type} {...rest} >{children}</button>
      
  )
}

export default Button