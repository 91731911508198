import React, { useEffect, useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import InfoBtnRight from "../../../icon/FloodIcons/InfoBtnRight";
import { useForm } from "react-hook-form";
// images paths
const individual = "assets/flood-images/mr.jpeg";
const business = "assets/flood-images/business.jpg";

const Email = ({ formData, setFormData, next }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      fullname: formData.fullname,
      email: formData.email,
    },
  });

  const handleNext1 = (data) => {
    setFormData(() => {
      return { ...formData, ...data };
    });
    next();
  };

  return (
    <div className="row justify-content-center">
      <form className="col-md-7" onSubmit={handleSubmit(handleNext1)}>
        <Card.Body className="d-flex flex-column align-items-center">
          <Card.Title className="cardTitle">Welcome!</Card.Title>
          <Card.Subtitle className="cardSubTitle mt-2">
            Let’s start with your personal information
          </Card.Subtitle>
        </Card.Body>
      
        <div className=" row d-flex flex-column align-items-center">
          <div className="col-12 mb-5">
            <img src="/assets/flood-images/flood-home.png" width={"100%"} className="rounded"/>
          </div>
          <div className="col-12">
          <label className="label-page9">Your Name</label>
          <input
            type="text"
            placeholder="Your Name ..."
            className="input-page9  mb-2"
            {...register("fullname", { required: true })}
          />
          <div className="error-full-name">
            {errors && errors.fullname ? (
              <span>Error! Not a valid Name</span>
            ) : null}
          </div>
          </div>
          <div className="col-12">
          <label className="label-page9">Your Email Address</label>
          <input
            type="email"
            placeholder="Your Email..."
            className="input-page9  mb-2"
            {...register("email", { required: true })}
          />
          <div className="error-full-name">
            {errors && errors.email ? (
              <span>Error! Not a valid email</span>
            ) : null}
          </div>
          </div>
        </div>
        <div className="d-flex gap-3 justify-content-center mt-4">
          <button type="submit" className="info-btn-common">
            <InfoBtnRight />
          </button>
        </div>
      </form>
    </div>
  );
};

export default Email;
