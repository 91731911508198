import React from 'react'

const BorderText = () => {
  return (
    <div className='borderText'>
        <svg width="100%" height="22" viewBox="0 0 271 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 8.77505C35.7769 6.35597 162.365 1.65103 266 8.77508C212.204 8.77507 142.114 9.49544 96.9843 17" stroke="#CC3333" stroke-width="7" stroke-linejoin="round"/>
        </svg>

    </div>
  )
}

export default BorderText