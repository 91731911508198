import React, { useState } from "react";

// import icons
import RadioIcon from "../../../icon/FloodIcons/RadioIcon";
import InfoBtnLeft from "../../../icon/FloodIcons/InfoBtnLeft";
import InfoBtnRight from "../../../icon/FloodIcons/InfoBtnRight";
import { useForm } from "react-hook-form";

const Mortage = ({ formData, setFormData, previous, next }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm({
    defaultValues: {
      mortage: formData.mortage.isTrue,
      mortageName: formData.mortage.mortageName,
      loanNumber: formData.mortage.loanNumber,
      mortageAddress: formData.mortage.mortageAddress,     
    },
  });
  const mortageValue = watch("mortage");
  const handleNext = (data) => {
    const newdata = {
      ...formData,
      mortage: {
        isTrue: data.mortage,
        mortageName: data.mortageName,
        loanNumber: data.loanNumber,
        mortageAddress: data.mortageAddress,
      }
    };

    setFormData(newdata);
    next();
  };

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <p className="cardSubTitle">
        Do you have a mortgage on the property?
      </p>
      <div className="row d-flex justify-content-center row-gap-custom">
        <div class="col-md-3 col-6">
          <label class=" radio-page6">
            <input
              id="Red"
              type="radio"
              name="mortage"
              value="Yes"
              className="input-page6"
              {...register("mortage")}
            />
            <span className="span-page6 ">Yes </span>
          </label>
        </div>
        <div class="col-md-3 col-6">
          <label class=" radio-page6">
            <input
              type="radio"
              name="mortage"
              value="No"
              className="input-page6"
              {...register("mortage")}
            />
            <span className="span-page6 "> No</span>
          </label>
        </div>
      </div>
      {mortageValue === "Yes" && (
        <div className="row justify-content-center  mt-3">
          <p>If Yes! Please provide details</p>
          <div className="col-12 col-md-7 mb-3">
            <p className="label-page9">Mortage Name</p>
            <input
              type="text"
              id="reason"
              placeholder="Your Name..."
              {...register("mortageName", { required: true })}
              className="input-page9"
            />
            {errors.mortageName && (
              <span className="text-danger">Mortage is required</span>
            )}
          </div>
          <div className="col-12 col-md-7 mb-3">
            <p className="label-page9">Loan Number</p>
            <input
              type="text"
              id="reason"
              placeholder="Your Loan Number..."
              {...register("loanNumber", { required: true })}
              className="input-page9"
            />
            {errors.loanNumber && (
              <span className="text-danger">Loan Number is required</span>
            )}
          </div>
          <div className="col-12 col-md-7 mb-3">
            <p className="label-page9">Street Address</p>
            <input
              type="text"
              id="reason"
              placeholder="Your Address..."
              {...register("mortageAddress", { required: true })}
              className="input-page9"
            />
            {errors.mortageAddress && (
              <span className="text-danger">Mortage Address is required</span>
            )}
          </div>
         
        
        
        </div>
      )}


      <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
        <button type="submit" className="info-btn-common">
          <InfoBtnRight />
        </button>
        <button onClick={() => previous()} className="info-btn-common">
          <InfoBtnLeft />
        </button>
      </div>
    </form>
  );
};

export default Mortage;
