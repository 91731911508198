import React from 'react'
import { Card, Form } from 'react-bootstrap';
import InfoBtnLeft from '../../../icon/FloodIcons/InfoBtnLeft';
import InfoBtnRight from '../../../icon/FloodIcons/InfoBtnRight';

// images paths
const SingleHomeImage = 'assets/flood-images/single-family.jpeg';
const MobileHomeImage = 'assets/flood-images/remote-home.jpeg';
const TriplexImage = 'assets/flood-images/2-4-family.jpeg';
const QuadplexImage = 'assets/flood-images/other.jpg';

const OccupancyTypeInfo = ({ formData, setFormData, previous, next }) => {
    return (
        <>
            <Card.Body className='d-flex flex-column align-items-center'>
                <Card.Title className='cardTitle '>Occupancy Type</Card.Title>
                <Card.Subtitle className="cardSubTitle ">Select the occupancy type of the property</Card.Subtitle>
            </Card.Body>
            <div className='construction-type'>
                <div className="d-flex gap-2 flex-wrap justify-content-center">
                    
                        <label class="radio">
                            <input
                                type="radio"
                                name="radio"
                                value="Single Family"
                                checked={formData.occupancyType === 'Single Family'}
                                onChange={(e) =>{
                                    setFormData({ ...formData, occupancyType: e.target.value })
                                    next()
                                }}
                            />
                            <span >
                                <img src={SingleHomeImage} alt='SingleHomeImage' className='genderImageMargin' />
                                Single Family
                            </span>
                        </label>
                  
                    
                        <label class="radio">
                            <input
                                type="radio"
                                name="radio"
                                value="Mobile Home"
                                checked={formData.occupancyType === 'Mobile Home'}
                                onChange={(e) =>{
                                    setFormData({ ...formData, occupancyType: e.target.value })
                                    next()
                                }}
                            />
                            <span >
                                <img src={MobileHomeImage} alt='DuplexImage' className='genderImageMargin' />
                                Mobile Home</span>
                        </label>
                    
                    
                        <label class="radio">
                            <input
                                type="radio"
                                name="radio"
                                value="2-4 Family"
                                checked={formData.occupancyType === '2-4 Family'}
                                onChange={(e) =>{
                                    setFormData({ ...formData, occupancyType: e.target.value })
                                    next()
                                }}
                            />
                            <span>
                                <img src={TriplexImage} alt='TriplexImage' className='genderImageMargin' />
                               <p>2-4 Family</p>
                            </span>
                        </label>
                    
                    
                        <label class="radio">
                            <input
                                type="radio"
                                name="radio"
                                value="Other Residential"
                                checked={formData.occupancyType === 'Other Residential'}
                                onChange={(e) =>{
                                    setFormData({ ...formData, occupancyType: e.target.value })
                                    next()
                                }}
                            />
                            <span >
                                <img src={QuadplexImage} alt='QuadplexImage' className='genderImageMargin' />
                                Other Residential
                            </span>
                        </label>
                    

                </div>
                <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
          <button onClick={() => next()} className="info-btn-common">
            <InfoBtnRight />
          </button>
          <button onClick={() => previous()} className="info-btn-common">
            <InfoBtnLeft />
          </button>
        </div>
            </div>
        </>
    )
}

export default OccupancyTypeInfo
