import React, { useState } from "react";
import InfoBtnLeft from "../../../icon/FloodIcons/InfoBtnLeft";
import InfoBtnRight from "../../../icon/FloodIcons/InfoBtnRight";
import { useForm } from "react-hook-form";
import CheckIcon from "../../../icon/FloodIcons/CheckIcon";
const PhoneBusinessMailInfo = ({ formData, setFormData, previous, final }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      phone: formData.phone,
      businessName: formData.businessName,
      mailAddress1: formData.mailAddress1,
      mailAddress2: formData.mailAddress2,
      startDate: formData.startDate,
    },
  });
  const phoneRegex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  const [isClicked, setIsClicked] = useState(false);

  const handleNext = (data) => {
    setIsClicked(true)
    const newdata = { ...formData, ...data }
    setFormData(() => {
      return newdata;
    });
    final(newdata)
    setIsClicked(false)
  };
  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className="row justify-content-center">
        <div className="col-md-7">
          <div className="">
            <h4 className="para1-page9 mb-4">
              Lastly, please confrim the following.
            </h4>
            <div className="">
              <label className="label-page9">Your phone number</label>
              <input
                type="text"
                name="phone"
                className="input-page9"
                placeholder="e.g +12343234"
                {...register("phone", {
                  required: "Phone number is required",
                })}
              />
            </div>
            <p className="para2-page9">
              *we will use phone number to verify important information
              regarding property.
            </p>
            <div className="error-full-name">
              {errors.phone && <span>{errors.phone?.message}</span>}
            </div>
            <p className=" fw-medium mt-5">
              If the owner of this property is a Business (LLC, Trust, or
              similar), please enter the Business Name below. This is the name
              that will appear as the Named Insured on the policy.
            </p>
            <input
              type="text"
              name="businessName"
              className="input-page9"
              placeholder="Business Name (Optional)"
              {...register("businessName")}
            />
            {/* <div className="error-full-name">
              {errors.businessName && <span>Error! Business Name is required</span>}
            </div> */}
            <hr className="mt-4 mb-5" />

            <div className="d-flex flex-column">
              <label className="label-page9">Your mailing address</label>
              <input
                type="text"
                name="mailAddress1"
                className="input-page9 input-gap-page9"
                placeholder="Your address line 1"
                {...register("mailAddress1", { required: true })}
              />
              <div className="error-full-name">
              {errors.mailAddress1 && <span>Error! Mail Address is required</span>}
            </div>
            </div>
            <p className="para2-page9">
              You will receive your quote once you click submit. If we need more
              information to provide you with a quote, we'll be in touch.
            </p>
            <div className="d-flex flex-column">
              <label className="label-page9">When would you like your flood insurance policy to start?</label>
              <input
                type="date"
                name="startDate"
                className="input-page9 input-gap-page9"
                placeholder="Your address line 1"
                {...register("startDate", { required: true })}
              />
              <div className="error-full-name">
              {errors.startDate && <span>Error! Date Address is required</span>}
            </div>
             
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
        <button disabled={isClicked} type="submit" className="info-btn-common">
          <CheckIcon />
        </button>
        <button onClick={() => previous()} className="info-btn-common">
          <InfoBtnLeft />
        </button>
      </div>
    </form>
  );
};

export default PhoneBusinessMailInfo;
