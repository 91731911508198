import React, { useState } from "react";

// import icons
import RadioIcon from "../../../icon/FloodIcons/RadioIcon";
import InfoBtnLeft from "../../../icon/FloodIcons/InfoBtnLeft";
import InfoBtnRight from "../../../icon/FloodIcons/InfoBtnRight";
import { useForm } from "react-hook-form";

const FloodInfo = ({ formData, setFormData, previous, next }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      floodLoss: formData.floodLoss,
      previousFlooding: formData.previousFlooding.isTrue,
      noOfFloors: formData.noOfFloors,
    },
  });
  const previousFloodingValue = watch("previousFlooding");
  const handleNext = (data) => {
   const newdata = {...formData, previousFlooding: {isTrue: data.previousFlooding, reason: data.reasonForFlooding}, noOfFloors: data.noOfFloors}
   setFormData(newdata)
    next()
  };

  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <p className="cardSubTitle">
        Flood loss w/in 5 years or repetitive loss?
      </p>
      <div className="row d-flex justify-content-center row-gap-custom mb-4">
        <div class="col-md-3 col-6">
          <label class=" radio-page6">
            <input
              id="Red"
              type="radio"
              name="floodLoss"
              value="Yes"
              className="input-page6"
              {...register("floodLoss")}
            />
            <span className="span-page6 ">Yes, it is </span>
          </label>
        </div>
        <div class="col-md-3 col-6">
          <label class=" radio-page6">
            <input
              type="radio"
              name="floodLoss"
              value="No"
              className="input-page6"
              {...register("floodLoss")}
            />
            <span className="span-page6 "> No It's not </span>
          </label>
        </div>
      </div>
      <p className="cardSubTitle">
        Has the property experienced any previous flooding incidents?
      </p>
      <div className="row d-flex justify-content-center row-gap-custom mb-4">
        <div class="col-md-3 col-6">
          <label class=" radio-page6">
            <input
              id="Red"
              type="radio"
              name="previousFlooding"
              value="Yes"
              className="input-page6"
              {...register("previousFlooding")}
            />
            <span className="span-page6 ">Yes, it is </span>
          </label>
        </div>
        <div class="col-md-3 col-6">
          <label class=" radio-page6">
            <input
              type="radio"
              name="previousFlooding"
              value="No"
              className="input-page6"
              {...register("previousFlooding")}
            />
            <span className="span-page6 "> No It's not </span>
          </label>
        </div>
      </div>
      {previousFloodingValue === "Yes" && (
        <div className="row justify-content-center  mt-3 mb-4">
         
          <div className="col-md-6">
          <p>
             If Yes! Please provide a reason:
            </p>
            <input
              type="text"
              id="reason"
              placeholder="Your Reason..."
              {...register("reasonForFlooding", { required: true })}
              className="input-number-page7"
            />
            {errors.reasonForFlooding && (
              <span className="text-danger">Reason is required</span>
            )}
          </div>
        
        </div>
      )}
     
      <div className="row  justify-content-center mb-4">
        <div className="col-md-6">
        <p className="cardSubTitle">
        Number of Floors above ground?
      </p>
        <input
          type="number"
          name="noOfFloors"
          className="input-number-page7"
          {...register("noOfFloors", { required: true })}
        />
        <div className="error-full-name">
        {errors.noOfFloors ? (
          <span>Error! Number of floors are required</span>
        ) : null}
      </div>
        </div>
      </div>
      
      <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
        <button type="submit" className="info-btn-common">
          <InfoBtnRight />
        </button>
        <button onClick={() => previous()} className="info-btn-common">
          <InfoBtnLeft />
        </button>
      </div>
    </form>
  );
};

export default FloodInfo;
