import React from 'react'
import BorderText from "../BorderText/border-text";
import GetAQoute from '../Button/Get A Qoute/GetAQoute';

const StartSaving = () => {
  return (
    <section className='startSaving py-5'>
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-lg-5 col-md-6 col-sm-12'>
                    <img src='/assets/images/flood.gif'  alt='image'/>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-12 d-flex align-items-center' >
                    <div>
                    <h1 >Start <span className='textStyle'>Saving <BorderText /></span> Today</h1>
                    <p className='text-secondary'>Begin your journey to financial security with Oqvic Insurance. Explore our tailored insurance solutions designed to protect what matters most to you. Start saving today and ensure a worry-free tomorrow with Oqvic. </p>
                    <GetAQoute />
                    </div>
                </div>

            </div>
        </div>
    </section>
  )
}

export default StartSaving