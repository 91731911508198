import React,{useState} from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
function SecondHomeSection() {
    const [image,setImage]=useState("./assets/images/secHome.png")
  
const imgHandlerHome= ()=>{
    setImage("./assets/images/secHome.png")
}
const imgHandlerAuto= ()=>{
    setImage("./assets/images/autotab2.jpg")
}
const imgHandlerBusiness= ()=>{
    setImage("./assets/images/businesstab2.jpg")
}

    return (
        <>
            <div className=''>
                <div className='image-container '>
                    <img className='bkg-img w-100 h-100 position-absolute object-fit-cover' src={image} alt="" />
                  
                      <div className='tabs-container card-radius  box-shadow'>
                <Tabs
                    transition={false}
                    defaultActiveKey="home"
                    id="uncontrolled-tab-example"
                    className="Tabs mb-3 "
                    fill
                >
                    <Tab className=" Tab ps-4 pe-4" eventKey="home" title={<div className='tab-title' onClick={imgHandlerHome}>HOME</div>} >
                        <div>
                            <h1 className='txt-fw-700 my-4'>Compare Quotes <br />
                                all in one place. </h1>
                            <div className="search">
                                <input type="search" name="" id="" placeholder='  Enter your Zip Code here app' />
                                <button className='txt-14'>View my quotes</button>
                            </div>
                            <p className='text-center mt-2 mb-2'>Or call <span className='txt-secondary'> 201-898-2013</span> for a quote</p>
                        </div>
                    </Tab>
                    <Tab className=" Tab ps-4 pe-4" eventKey="profile" title={<div className='tab-title' onClick={imgHandlerAuto}>AUTO</div>}>
                        <div>
                            <h1 className='txt-fw-700 my-4'>Compare Quotes <br />
                                all in one place. </h1>
                            <div className="search">
                                <input type="search" name="" id="" placeholder='  Enter your Zip Code here app' />
                                <button className='txt-14'>View my quotes</button>
                            </div>
                            <p className='text-center mt-2 mb-2'>Or call <span className='txt-secondary'> 201-898-2013</span> for a quote</p>
                        </div>
                    </Tab>
                    <Tab className=" Tab ps-4 pe-4" eventKey="contact" title={<div className='tab-title' onClick={imgHandlerBusiness}>BUSINESS</div>} >
                        <div>
                            <h1 className='txt-fw-700 my-4'>Compare Quotes <br />
                                all in one place. </h1>
                            <div className="search">
                                <input type="search" name="" id="" placeholder='  Enter your Zip Code here app' />
                                <button className='txt-14'>View my quotes</button>
                            </div>
                            <p className='text-center mt-2 mb-2'>Or call <span className='txt-secondary'> 201-898-2013</span> for a quote</p>
                        </div>
                    </Tab>
                </Tabs>
            </div>
                  </div>
            </div>
        </>
    )
}

export default SecondHomeSection