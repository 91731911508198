import React from 'react'
import { Card, Form } from 'react-bootstrap';
import InfoBtnLeft from '../../../icon/FloodIcons/InfoBtnLeft';
import InfoBtnRight from '../../../icon/FloodIcons/InfoBtnRight';

// images paths
const basement = 'assets/flood-images/basement.jpeg';
const split = 'assets/flood-images/split-level-basement.jpeg';
const enclosure = 'assets/flood-images/elevated-enclosure.jpeg';
const withoutEnclosure = 'assets/flood-images/elevated-without-enclosure.jpeg';

const FoundationType = ({ formData, setFormData, previous, next}) => {
    return (
        <>
            <Card.Body className='d-flex flex-column align-items-center'>
                <Card.Title className='cardTitle'>Foundation Type</Card.Title>
                <Card.Subtitle className="cardSubTitle">Select the foundation type</Card.Subtitle>
            </Card.Body>
            <div className='construction-type'>
                <div className="d-flex gap-2  flex-wrap justify-content-center">
                   
                        <label class="radio">
                            <input
                                type="radio"
                                name="foundationType"
                                value="Basement"
                                checked={formData.foundationType === 'Basement'}
                                onChange={(e) => {
                                    setFormData({ ...formData, foundationType: e.target.value })
                                    next()
                                }}
                            />
                            <span className=' '>
                                <img src={basement} alt='QuadplexImage' className='genderImageMargin' />
                                Basement
                            </span>
                        </label>
                    
                        <label class="radio">
                            <input
                                type="radio"
                                name="foundationType"
                                value="Split"
                                checked={formData.foundationType === 'Split'}
                                onChange={(e) => {
                                    setFormData({ ...formData, foundationType: e.target.value })
                                    next()
                                }}
                            />
                            <span className=''>
                                <img src={split} alt='QuadplexImage' className='genderImageMargin' />
                                <p>Split Level Basement</p>
                            </span>
                        </label>
                        <label class="radio">
                            <input
                                type="radio"
                                name="foundationType"
                                value="Enclosure"
                                checked={formData.foundationType === 'Enclosure'}
                                onChange={(e) => {
                                    setFormData({ ...formData, foundationType: e.target.value })
                                    next()
                                }}
                            />
                            <span className=''>
                                <img src={enclosure} alt='QuadplexImage' className='genderImageMargin' />
                                Elevated with enclosure
                            </span>
                        </label>
                        <label class="radio">
                            <input
                                type="radio"
                                name="foundationType"
                                value="NonEnclosure"
                                checked={formData.foundationType === 'NonEnclosure'}
                                onChange={(e) => {
                                    setFormData({ ...formData, foundationType: e.target.value })
                                    next()
                                }}
                            />
                            <span className=' '>
                                <img src={withoutEnclosure} alt='QuadplexImage' className='genderImageMargin' />
                                Elevated without enclosure
                            </span>
                        </label>
                     
                    
                </div>
            </div>
            <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
          <button onClick={() => next()} className="info-btn-common">
            <InfoBtnRight />
          </button>
          <button onClick={() => previous()} className="info-btn-common">
            <InfoBtnLeft />
          </button>
        </div>
        </>
    )
}

export default FoundationType
