import React from 'react'

const CheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="37" height="36" viewBox="0 0 37 36" fill="none">
            <path d="M11 18L18.5 25.5L33.5 10.5" stroke="#4B465C" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M11 18L18.5 25.5L33.5 10.5" stroke="white" stroke-width="2.25" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.2955 17.2045C3.85616 16.7652 3.14384 16.7652 2.7045 17.2045C2.26517 17.6438 2.26517 18.3562 2.7045 18.7955L4.2955 17.2045ZM10.2045 26.2955C10.6438 26.7348 11.3562 26.7348 11.7955 26.2955C12.2348 25.8562 12.2348 25.1438 11.7955 24.7045L10.2045 26.2955ZM17.7045 17.2045C17.2652 17.6438 17.2652 18.3562 17.7045 18.7955C18.1438 19.2348 18.8562 19.2348 19.2955 18.7955L17.7045 17.2045ZM26.7955 11.2955C27.2348 10.8562 27.2348 10.1438 26.7955 9.7045C26.3562 9.26517 25.6438 9.26517 25.2045 9.7045L26.7955 11.2955ZM2.7045 18.7955L10.2045 26.2955L11.7955 24.7045L4.2955 17.2045L2.7045 18.7955ZM19.2955 18.7955L26.7955 11.2955L25.2045 9.7045L17.7045 17.2045L19.2955 18.7955Z" fill="#4B465C" />
            <path d="M4.2955 17.2045C3.85616 16.7652 3.14384 16.7652 2.7045 17.2045C2.26517 17.6438 2.26517 18.3562 2.7045 18.7955L4.2955 17.2045ZM10.2045 26.2955C10.6438 26.7348 11.3562 26.7348 11.7955 26.2955C12.2348 25.8562 12.2348 25.1438 11.7955 24.7045L10.2045 26.2955ZM17.7045 17.2045C17.2652 17.6438 17.2652 18.3562 17.7045 18.7955C18.1438 19.2348 18.8562 19.2348 19.2955 18.7955L17.7045 17.2045ZM26.7955 11.2955C27.2348 10.8562 27.2348 10.1438 26.7955 9.7045C26.3562 9.26517 25.6438 9.26517 25.2045 9.7045L26.7955 11.2955ZM2.7045 18.7955L10.2045 26.2955L11.7955 24.7045L4.2955 17.2045L2.7045 18.7955ZM19.2955 18.7955L26.7955 11.2955L25.2045 9.7045L17.7045 17.2045L19.2955 18.7955Z" fill="white" />
        </svg>
    )
}

export default CheckIcon
