import React from "react";
import { useNavigate } from "react-router";
const GetAQoute = () => {
  const navigate = useNavigate()
  return (
    <div className="getQoute">
      <button className="py-3 px-4" onClick={() => navigate('/flood-info')}>Get A Quote</button>
    </div>
  );
}

export default GetAQoute;
