import React, { useState } from "react";

// import icons
import SearchIcon from "../../../icon/FloodIcons/SearchIcon";
import HorizontalLineIcon from "../../../icon/FloodIcons/HorizontalLineIcon";
import InfoBtnLeft from "../../../icon/FloodIcons/InfoBtnLeft";
import InfoBtnRight from "../../../icon/FloodIcons/InfoBtnRight";
import { useForm } from "react-hook-form";
// images path
const MapImage = "assets/flood-images/map.png";

const AddressMapInfo = ({ formData, setFormData, previous, next }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    
  } = useForm({
    defaultValues:{
      address: formData.address,
      unit: formData.unit
    }
  })

  const onSubmit =  (data) => { 
    setFormData({...formData, ...data})
    next()  
}
  
  
  return (
    <div className="row justify-content-center">
      <form className="col-md-7" onSubmit={handleSubmit(onSubmit)}> 
        <div className="d-flex flex-column align-items-center">
          <p className="heading-p-page2">
            Great to meet you
            <span className="heading-span-page2">
              {" "}
              {formData.fullname}!{" "}
            </span>
            Please provide the address of property you would like to insure
          </p>
        
          <div className="form-div">
            <div className="text-div-page2">
              <span className="span1-page2">Property Address</span>
              <span className="span2-page2">Unit #</span>
            </div>
            <div className="input-div-page2">
              <div className="d-flex  align-items-center gap-3">
                <div className="searchIcon">
                  <SearchIcon />
                </div>
                <input
                  type="text"
                  id="address"
                  {...register('address', {required: true})}
                  placeholder="Enter address of home you want to insure"
                  className="input1-page2"
                />
              </div>
              <input
                type="number"
                id="unitNumber"
                {...register('unit')}
                placeholder="Unit"
                className="input2-page2"
              />
            </div>
          </div>
          <div className="error-full-name mt-3">
            {errors.address && <span>Error! Address is required.</span> || null}
            <br/>
            {/* {errors.unit && <span>Error! Unit is required.</span> || null} */}
          </div>
          <div className="map-image">
            <img src={MapImage} alt="MapImage" />
          </div>
          
          <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
            <button
              className="info-btn-common"
              type="submit"
            >
              <InfoBtnRight />
            </button>
            <button onClick={() => previous()} className="info-btn-common">
              <InfoBtnLeft />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddressMapInfo;
