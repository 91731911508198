import React from "react";
import BorderText from "../BorderText/border-text";
import { motion } from "framer-motion";
const WhyOqvic = () => {
  return (
    <section className="whyOcvic py-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">
                Why <span className="textStyle">Choose{" "} <BorderText /></span> Ocvic
            </h1>
          </div>
        </div>
        <div className="row mt-5 justify-content-center">
            <motion.div 
             initial={{ opacity: 0,  transform: 'translateY(-10px)' }}
             whileInView={{ opacity: 1, transform: 'translateY(0)'}}
             transition={{ delay: .5, type: 'ease' }}
            className="col-lg-3 col-md-4 text-center">
                <img  src="/assets/images/lowpremium.png" alt="image"/>
                <h4 className="my-4">Incredibly <br/> low premiums</h4>
                <p className="text-secondary">At Oqvic, we understand that flood insurance shouldn't break the bank. Our mission is to help you protect your home and your finances without overpaying.</p>
            </motion.div>
            <motion.div 
             initial={{ opacity: 0,  transform: 'translateY(-10px)' }}
             whileInView={{ opacity: 1, transform: 'translateY(0)'}}
             transition={{ delay: .7, type: 'ease' }}
            className="col-lg-3 col-md-4 text-center">
                <img  src="/assets/images/easy.png"alt="img" />
                <h4 className="my-4">Super <br/> Quick & Easy</h4>
                <p className="text-secondary">Oqvic offers lightning-fast insurance services, ensuring effortless processes and immediate support. Your peace of mind is just a click away with our swift, reliable solutions.</p>
            </motion.div>
            <motion.div 
             initial={{ opacity: 0,  transform: 'translateY(-10px)' }}
             whileInView={{ opacity: 1, transform: 'translateY(0)'}}
             transition={{ delay: 1, type: 'ease' }}
            className="col-lg-3 col-md-4 text-center">
                <img  src="/assets/images/quick.png" alt="image"/>
                <h4 className="my-4">Quick <br/> Customer Support</h4>
                <p className="text-secondary">Oqvic guarantees rapid and reliable support, available 24/7. Our dedicated team ensures swift solutions, making your experience seamless and stress-free. Your satisfaction is our priority.</p>
            </motion.div>
          
        </div>
        
      </div>
    </section>
  );
};

export default WhyOqvic;
