import React from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../Logo/Logo';
import { json, useLocation, useNavigate } from 'react-router-dom';
function HeaderContact({exploringData}) {
  const location = useLocation()
  const navigate = useNavigate()
  function handleRouting(){
    if(location.pathname != '/flood-page' &&  location.pathname != '/flood-info'){
      navigate('/')
      return;
    } 
  }
  return (
    <>
      <Navbar expand="lg" className="bkg-tertiary">
        <Container className=' '>
          <Navbar.Brand onClick={() => handleRouting()}>
           <Logo />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className='justify-content-end'>
            {/* <Nav className="me-auto ms-auto navbar-nav nav-item-header d-flex justify-content-between pe-3 ps-3">
            <li className=" nav-item   dropdown">
          <a className="nav-link   txt-16  txt-fw-700 txt-primary" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Insurance and more <img height='10px ' width='15px'   src="./assets/images/dropdownPath.png" alt="" /> 
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr clasNames="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="/">Something else here</a></li>
          </ul>
        </li>
        <li className=" nav-item dropdown ">
          <a className="nav-link  txt-16  txt-fw-700 txt-primary" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Our company <img height='10px ' width='15px'   src="./assets/images/dropdownPath.png" alt="" /> 
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr clasNames="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="/">Something else here</a></li>
          </ul>
        </li>
        <li className=" nav-item dropdown ">
          <a className="nav-link  txt-16  txt-fw-700 txt-primary" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Resources <img height='10px ' width='15px'   src="./assets/images/dropdownPath.png" alt="" /> 
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr clasNames="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="/">Something else here</a></li>
          </ul>
        </li>
        <li className="  nav-item dropdown ">
          <a className="nav-link  txt-16  txt-fw-700  txt-primary" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Locations   <img height='25px ' width='20px'   src="./assets/images/map-pin.png" alt="" /> 
          </a>
          <ul className="dropdown-menu">
            <li><a className="dropdown-item" href="#">Action</a></li>
            <li><a className="dropdown-item" href="#">Another action</a></li>
            <li><hr clasNames="dropdown-divider"/></li>
            <li><a className="dropdown-item" href="/">Something else here</a></li>
          </ul>
        </li>
            </Nav> */}
           
            <div className='navbarHeading'>
            <p className='txt-16'>CONTACT US</p>
            <p className='p2 txt-16' >
              <img height='20px ' width='20px'   src="./assets/images/phone-call.png" alt="" /><span > 201-898-2013</span>
              </p>
          </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default HeaderContact