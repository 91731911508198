import React from 'react'

const Logo = () => {
  return (
    
         <div className='navbarIconDiv'>
              <img className='navbarIcon' src="./assets/images/OQVIC.png" alt="" />
              <div className='navbarHeading'>
                <p className='p1 txt-primary'>OQVIC</p>
                <p className='p2'>QUALITY INSURANCE</p>
              </div>
            </div>
    
  )
}

export default Logo