import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import icons
import HorizontalRedLineIcon from "../../../icon/FloodIcons/HorizontalLineRedIcon";
import InfoBtnLeft from "../../../icon/FloodIcons/InfoBtnLeft";
import InfoBtnRight from "../../../icon/FloodIcons/InfoBtnRight";

const BuildingGeneralInfo = ({ formData, setFormData, previous, next }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    
  } = useForm({
    defaultValues:{
      constructionYear: formData.constructionYear,
      sqFoot: formData.sqFoot,
      buildCost: formData.buildCost,
      contentCost: formData.contentCost,
    }
  })

  const handleNext = (data) => { 
    setFormData(() => {
      return {...formData, ...data}
   }) 
    next()
  }
  return (
    <form onSubmit={handleSubmit(handleNext)}>
      <div className="row justify-content-center">
        <div className="col-md-7">
          <div className=" div-margin-top-page8">
            <div className="div-page8">
              <p className="span1-page2 text-start">Building Construction Year</p>
              <input
                type="number"
                name="constructionYear"
                className="input-border "
                placeholder="Enter construction year"
               {...register('constructionYear', {required: true, min: 1})}
              />
            </div>
            <div className="error-full-name">
            {errors.constructionYear && <span>Error! construction year is required, Min value required is 1</span>}
            </div>
          </div>

          <div className=" div-margin-top-page8">
            <div className="div-page8">
              <p className="span1-page2 text-start">Building Square Footage</p>
              <input
                type="number"
                name="sqfoot"
                className="input-border "
                placeholder="Enter sq"
                {...register('sqFoot',{required: true})}
              />

              <span className="span-right-page8 span-custom-style">
                | sq/ft
              </span>
            </div>
            <div className="error-full-name">
              {errors.sqFoot && <span>Error! square Foot  is required</span>}
            </div>
          </div>

          <div className="  div-margin-top-page8">
            <div className="div-page8">
              <p className="span1-page2 text-start">Building Replacement Cost</p>
              <span className="span-left-page8 span-custom-style">$</span>

              <input
                type="number"
                name="buildCost"
                className="input-border"
                placeholder="Enter cost"
               {...register('buildCost',{required: true})}
              />
              {/* <span className="span-right-page8  span-custom-style">| .00</span> */}
            </div>
            <div className="error-full-name">
              {errors.buildCost && <span>Error! Building replacement cost  is required</span>}
            </div>
          </div>

          <div className="div-margin-top-page8">
            <div className="div-page8">
              <p className="span1-page2 text-start">Contents Replacement Cost</p>
              <span className="span-left-page8 span-custom-style">$</span>
              <input
                type="number"
                name="contentCost"
                className="input-border"
                placeholder="Enter cost"
                {...register('contentCost', {required: true})}
              />
              {/* <span className="span-right-page8 span-custom-style">| .00</span> */}
            </div>
            <div className="error-full-name">
              {errors.contentCost && <span>Error! Content Replacement cost  is required</span>}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
        <button type="submit" className="info-btn-common">
          <InfoBtnRight />
        </button>
        <button onClick={() => previous()} className="info-btn-common">
          <InfoBtnLeft />
        </button>
      </div>
    </form>
  );
};

export default BuildingGeneralInfo;
