import React from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useLocation, useParams } from 'react-router';
import { dataArrayProtectToday } from '../../API/Data';

function ExploringHeroSection2() {
    const { pageName } = useParams();
    const result = dataArrayProtectToday.filter((itemHeading) => (itemHeading.heading === pageName));
    const bottomImg = result[0].bottomImg;

    // const location = useLocation();
    //   console.log(location.state)
    //   console.log(location.state?.heroImg)
    //   console.log(location.state?.bottomImg)
    //   const cardData = location.state?.heroImg

    return (
        <>
            <div className='mt-5'>
                <div className='image-container '>
                    <img className='bkg-img w-100 h-100 position-absolute ' src={bottomImg} alt="" />
                    <div className="tabs-container-responsive ">
                        <div className='tabs-container card-radius  box-shadow'>
                            <Tabs
                                transition={false}
                                defaultActiveKey="home"
                                id="uncontrolled-tab-example"
                                className="Tabs exploringTabs mb-3 bkg-red"
                                fill
                            >
                                <Tab className=" Tab ps-4 pe-4" eventKey="home" title={<div className='txt-fw-700 exploring-tab-title'>{pageName}</div>}>
                                    <div>
                                        <h1 className='txt-fw-700 my-4'>Compare Quotes <br />
                                            all in one place. </h1>
                                        <div className="search">
                                            <input type="search" name="" id="" placeholder='  Enter your Zip Code here app' />
                                            <button className='txt-14'>View my quotes</button>
                                        </div>
                                        <p className='text-center mt-2 mb-2'>Or call <span className='txt-secondary'> 201-898-2013</span> for a quote</p>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExploringHeroSection2