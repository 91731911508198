import React, { useEffect, useState } from "react";
import BorderText from "../BorderText/border-text";
import { motion, AnimatePresence } from "framer-motion";
import GetAQoute from "../Button/Get A Qoute/GetAQoute";

const Hero = () => {
  const images = [
    "/assets/images/hero-image.png",
    "/assets/images/hero-image-1.jpg",
    "/assets/images/hero-image-2.png",
  ];
  const [currentImage, setCurrentImage] = useState(0);

  const handleNextImage = () => {
    setCurrentImage((prevImage) => (prevImage + 1) % images.length);
  };

  const imageVariants = {
    initial: { transform: "scale(.8)", opacity: 0 },
    animate: {
      transform: "scale(1)",
      opacity: 1,
    },
    exit: { opacity: 0 },
  };

  const updateImage = () => {
    setCurrentImage((prev) => (prev + 1) % images.length);
  };
  useEffect(() => {
    const interval = setInterval(updateImage, 3000);
    return () => clearInterval(interval);
  });

  return (
    <section className="hero position-relative">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-5 col-sm-12">
            <h1>
              <span className="textStyle">
                Save Upto <BorderText />{" "}
              </span>{" "}
              50% on flood insurance with Oqvic
            </h1>
            <p className="my-4">
              Don't let high prices drown you - Get your quote today!
            </p>
           <GetAQoute />
          </div>
          <div className="col-md-4 col-sm-12 offset-md-2 d-md-block d-none">
            <div className="hero-image">
              {/* <img src='/images/hero-image.png' /> */}
              <AnimatePresence>
                <motion.img
                  key={currentImage}
                  src={images[currentImage]}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={imageVariants}
                  transition={{
                    type: 'spring',
                    bounce: .6,
                    
                  }}
                  onClick={handleNextImage}
                />
              </AnimatePresence>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
