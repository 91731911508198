import React from "react";
import { Accordion } from "react-bootstrap";
import { useNavigate } from "react-router";
import BorderText from "../../../components/BorderText/border-text";

const Thankyou = () => {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-center align-items-center  thankyou">
      <div className="waves">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#fff" fill-opacity=".3" d="M0,288L17.1,256C34.3,224,69,160,103,122.7C137.1,85,171,75,206,80C240,85,274,107,309,106.7C342.9,107,377,85,411,112C445.7,139,480,213,514,218.7C548.6,224,583,160,617,122.7C651.4,85,686,75,720,101.3C754.3,128,789,192,823,202.7C857.1,213,891,171,926,176C960,181,994,235,1029,250.7C1062.9,267,1097,245,1131,224C1165.7,203,1200,181,1234,154.7C1268.6,128,1303,96,1337,101.3C1371.4,107,1406,149,1423,170.7L1440,192L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"></path></svg>
      </div>
      <div className="waves">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
          <path
            fill="#fff"
            fill-opacity=".5"
            d="M0,128L17.1,149.3C34.3,171,69,213,103,240C137.1,267,171,277,206,261.3C240,245,274,203,309,202.7C342.9,203,377,245,411,250.7C445.7,256,480,224,514,213.3C548.6,203,583,213,617,218.7C651.4,224,686,224,720,218.7C754.3,213,789,203,823,181.3C857.1,160,891,128,926,122.7C960,117,994,139,1029,128C1062.9,117,1097,75,1131,58.7C1165.7,43,1200,53,1234,69.3C1268.6,85,1303,107,1337,138.7C1371.4,171,1406,213,1423,234.7L1440,256L1440,320L1422.9,320C1405.7,320,1371,320,1337,320C1302.9,320,1269,320,1234,320C1200,320,1166,320,1131,320C1097.1,320,1063,320,1029,320C994.3,320,960,320,926,320C891.4,320,857,320,823,320C788.6,320,754,320,720,320C685.7,320,651,320,617,320C582.9,320,549,320,514,320C480,320,446,320,411,320C377.1,320,343,320,309,320C274.3,320,240,320,206,320C171.4,320,137,320,103,320C68.6,320,34,320,17,320L0,320Z"
          ></path>
        </svg>
      </div>
      <div className="container py-5 position-relative">
        <div className="row justify-content-center">
          <div className=" col-12 position-relative mb-5">
            {/* <img src="/assets/flood-images/thankyou.png" width={300} /> */}
            <h1>Thank You! </h1>
            <p className="">
              Thank you! We have recieved you information. One of our agents
              will get back to you soon with the insurance qoute
            </p>
            <button
              className="rounded-pill go-home-btn"
              onClick={() => navigate("/flood-page")}
            >
              Go Home
            </button>
          </div>
          <div className="col-md-4">
            <h2>What <br/> to do next?</h2>
          </div>
          <div className="col-md-8">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>How to make Account on Oqvic?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Can I use multiple accounts?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>Can I use multiple accounts?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Can I use multiple accounts?</Accordion.Header>
                <Accordion.Body>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation ullamco
                  laboris nisi ut aliquip ex ea commodo consequat. Duis aute
                  irure dolor in reprehenderit in voluptate velit esse cillum
                  dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                  cupidatat non proident, sunt in culpa qui officia deserunt
                  mollit anim id est laborum.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
