import React from 'react'
import {winHandsData} from '../../../API/Data'
function WinHandsDown() {
    return (
        <>
            <div className='bkg-sky p-5 mt-5 me-0'>
                <p className='ifThisWere txt-16 txt-fw-600 mb-2 '>If this were a popularity contest,</p>
                <div className='weWinHands txt-30 txt-fw-700'>We win hands down.</div>
                <div className='thatsEasyTo'>
                    That's easy to say when you're America's highest- rated <br /> insurance
                    provider. Which we are.
                </div>
                <div className="container-fluid">
                    <div className="row">
                    {winHandsData.map(item => (
                            <div className="col-md-6 col-lg-3 col-xl-3 mt-5  key={item.id} ">
                            <div className='bg-white p-4 text-center rounded '>
                            <div className='d-flex justify-content-center align-items-center mb-2 '>
                            <img src={item.starImg} alt={`Image ${item.id}`} />
                            <img src={item.starImg} alt={`Image ${item.id}`} />
                            <img src={item.starImg} alt={`Image ${item.id}`} />
                            <img src={item.starImg} alt={`Image ${item.id}`} />
                            <img src={item.starImg} alt={`Image ${item.id}`} />
                            </div>

                            <h5 className='txt-fw-600 txt-red'>{item.heading}</h5>
                            <div className=''>
                              <p className='txt-fw-500'> {item.content.substring(0,100)}...</p>
                                
                            </div>
                            <img height='70' width='70' src={item.img} alt={`Image ${item.id}`} />
                            <p className='txt-20 txt-blue txt-fw-600 mt-2'>{item.agentName}</p>
                            <p className='txt-15 text-secondary '>Preston S., Greer, SC</p>

                            </div>
                        </div>
      ))}
                       
                    </div>
                </div>
            </div>
        </>
    )
}

export default WinHandsDown