import React from 'react'

function FindInsurance() {
    return (
        <>
            {/* INSURANCE CONTAINER  */}
            <div className="container-fluid m-0 bkg-sky">
                <div className="row justify-content-center align-items-center h-100">
                    <div className="col-md-3 text-center text-md-end bkg-red  py-4 ">
                        <p className="mb-0 text-light txt-fw-600 txt-24 me-2">Find Insurance</p>
                        <p className="mb-0 text-light txt-fw-600 me-2">To fit Your Needs</p>
                    </div>
                    <div className="col-md-3 text-center  py-4">
                        <div className='navbarIconDiv'>
                            <img src="./assets/images/Car 1.png" alt="" />
                            <div className='navbarHeading'>
                                <p className='pb-0 mb-0 text-start txt-18'>CAR</p>
                                <p className='pb-0 mb-0'>Insurance</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-center py-4">
                        <div className='navbarIconDiv'>
                            <img src="./assets/images/home 1.png" alt="" />
                            <div className='navbarHeading'>
                                <p className='pb-0 mb-0 text-start txt-18'>HOME</p>
                                <p className='pb-0 mb-0'>Insurance</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 text-center py-4">
                        <div className='navbarIconDiv'>
                            <img src="./assets/images/Layerbusiness 6 1.png" alt="" />
                            <div className='navbarHeading'>
                                <p className='pb-0 mb-0 text-start txt-18'>BUSINESS</p>
                                <p className='pb-0 mb-0'>Insurance</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* TRUSTED LINKS  */}

            <div className="container-fluid mt-3 mb-5">
                <div className="row justify-content-center align-items-center h-100 ">
                    <div className="col-md-3  d-flex align-items-center justify-content-center py-4 border-right border-md-bottom">
                        <img src="./assets/images/medal.png" alt="" />
                    </div>
                    <div className="col-md-9 justify-content-center align-items-center">
                        <div className="row d-flex align-items-center justify-content-center">
                            <div className=" col-6 col-md-3  col-lg-3 col-xl-3 text-center py-4">
                                <img height='40' width='100' className='align-center' src="./assets/images/forbes.png" alt="" />
                            </div>
                            <div className=" col-6 col-md-3  col-lg-3 col-xl-3 text-center   py-4">
                                <img height='40' width='100' src="./assets/images/inc.png" alt="" />
                            </div>
                            <div className=" col-6 col-md-3  col-sm-6 col-lg-3 col-xl-3 text-center  py-4">
                                <img  height='40' width='100' src="./assets/images/yahoo.png" alt="" />
                            </div>
                            <div className=" col-6 col-md-3   col-lg-3 col-xl-3 text-center  py-4">
                                <img  height='40' width='100' src="./assets/images/msn.png" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FindInsurance