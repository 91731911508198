import React from 'react'

function LoveOqvic() {
    return (
        <>
            <div className="container  pb-5 mt-5">
                <div className="paper-writing-service d-flex flex-column align-items-center  mb-3">
                    <h1 className="text-center my-5 fw-bold">Here’s why you’ll love OQVIC</h1>
                    <div className="container mt-5">
                        <div className="row ">
                            <div className="col-md-4">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img className='align-self-center' height='100' width='100' src="./assets/images/lowpremium.png" alt="" />
                                    </div>
                                    <h5 className='txt-fw-700 text-center py-3'>Incredibly low premiums</h5>
                                    <p className='text-center txt-12 txt-fw-500 '>Insuring things that matter to you shouldn't <br /> come at a cost. That's why when you buy from us<br /> you get insurance that fits your pocket.</p>
                            </div>
                            <div className="col-md-4">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img className='align-self-center' height='100' width='100' src="./assets/images/easy.png" alt="" />
                                    </div>
                                    <h5 className='txt-fw-600 text-center py-3 txt-fw-700'>Incredibly low premiums</h5>
                                    <p className='text-center  txt-12 txt-fw-500 '>
                                        We're easy, effortless and 100% digital. Forget <br /> paperwork, just access all our services with just a <br /> few clicks.</p>
                               
                            </div>
                            <div className="col-md-4">
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <img className='align-self-center' height='auto' width='100' src="./assets/images/quick.png" alt="" />
                                    </div>
                                    <h5 className='txt-fw-600 text-center py-3 txt-fw-700'>Incredibly low premiums</h5>
                                    <p className='text-center txt-12 txt-fw-500'>We're on a mission to make insurance claims<br /> stress-free. Just give us a call or file a claim online<br />and experience cashless, speedy claim <br /> settlernents.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LoveOqvic