import React from 'react'
import HeaderContact from '../../components/HeaderContact/HeaderContact'
import InsuranceSave from '../LandingPages/IsuranceSave.js/InsuranceSave'
import FindInsurance from '../LandingPages/FindInsurance/FindInsurance'
import PersonalizedQuotes from '../LandingPages/PersonalizedQuotes/PersonalizedQuotes'
import LoveOqvic from '../LandingPages/LoveOqvic/LoveOqvic'
import SecondHomeSection from '../LandingPages/SecondHomeSection/SecondHomeSection'
import ExploringHeroSection1 from '../../components/ExploringHeroSection1/ExploringHeroSection1'
import ExploringHeroSection2 from '../../components/ExploringHeroSection2/ExploringHeroSection2'
import FooterOqvic from '../../components/FooterOqvic/FooterOqvic'
// import { useNavigate } from 'react-router'

function ExploringPages() {
    // const navigate = useNavigate()
    
  return (
  <>
  <HeaderContact/>
  <ExploringHeroSection1/>
  <FindInsurance/>
  <PersonalizedQuotes/>
  <LoveOqvic/>
  <ExploringHeroSection2/>
  <FooterOqvic />
  </>
  )
}

export default ExploringPages