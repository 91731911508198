import React from "react";
import { Card, Form } from "react-bootstrap";
import InfoBtnLeft from "../../../icon/FloodIcons/InfoBtnLeft";
import InfoBtnRight from "../../../icon/FloodIcons/InfoBtnRight";

// images paths
const frame = "assets/flood-images/frame.jpg";
const masonry = "assets/flood-images/masonry.jpeg";
const stucco = "assets/flood-images/stucco.jpg";
const concrete = "assets/flood-images/concrete.jpeg";

const ConstructionTypeInfo = ({ formData, setFormData, previous, next }) => {
 
  return (
    <>
      <Card.Body className="d-flex flex-column align-items-center">
        <Card.Title className="cardTitle ">
          Construction Type
        </Card.Title>
        <Card.Subtitle className="cardSubTitle">
          Select the construction style of the property
        </Card.Subtitle>
      </Card.Body>
      <div className="construction-type ">
        <div className="d-flex justify-content-center gap-3 flex-wrap align-items-center">
          <label class="radio">
            <input
              type="radio"
              name="cunstruction"
              value="Frame"
              checked={formData.constructionType === "Frame"}
              onChange={(e) =>{
                setFormData({ ...formData, constructionType: e.target.value })
                next()
              }
                
              }
            />
            <span >
              <img
                src={frame}
                alt="SingleHomeImage"
                className="genderImageMargin"
              />
              Frame
            </span>
          </label>

          <label class="radio">
            <input
              type="radio"
              name="cunstruction"
              value="Masonry"
              checked={formData.constructionType === "Masonry"}
              onChange={(e) =>{
                setFormData({ ...formData, constructionType: e.target.value })
                next()
              }
              }
            />
            <span>
              <img
                src={masonry}
                alt="DuplexImage"
                className="genderImageMargin"
              />
              Masonry
            </span>
          </label>

          <label class="radio">
            <input
              type="radio"
              name="cunstruction"
              value="Stucco"
              checked={formData.constructionType === "Stucco"}
              onChange={(e) =>{
                setFormData({ ...formData, constructionType: e.target.value })
                next()
              }
              }
            />
            <span >
              <img
                src={stucco}
                alt="TriplexImage"
                className="genderImageMargin"
              />
              Stucco
            </span>
          </label>

          <label class="radio">
            <input
              type="radio"
              name="cunstruction"
              value="Concrete"
              checked={formData.constructionType === "Concrete"}
              onChange={(e) =>{
                setFormData({ ...formData, constructionType: e.target.value })
                next()
              }
              }
            />
            <span >
              <img
                src={concrete}
                alt="QuadplexImage"
                className="genderImageMargin"
              />
              Concrete
            </span>
          </label>
        </div>
        <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
          <button onClick={() => next()} className="info-btn-common">
            <InfoBtnRight />
          </button>
          <button onClick={() => previous()} className="info-btn-common">
            <InfoBtnLeft />
          </button>
        </div>
      </div>
    </>
  );
};

export default ConstructionTypeInfo;
