import React from 'react';
import CardMain from '../../../components/FloodComponents/CardMain/CardMain.js';
import HeaderContact from '../../../components/HeaderContact/HeaderContact.js';
import FooterOqvic from '../../../components/FooterOqvic/FooterOqvic.js';

const FloodInfo = () => {
    return (
        <div className='container-fluid'>
            <div className='row'>
               <HeaderContact />
            </div>
           <div className='row infoRow2 '>
           <div className='col infoMainText text-center '>
                    <p className='infoP1'>Flood Insurance</p>
                    <p className='infoP2'>Insure your prized possession in few easy steps</p>
                   <div className='container'>
                   <CardMain />
                   </div>
                </div>
           </div>
           <div className='row'>
            <FooterOqvic />
           </div>
        </div>
    )
}

export default FloodInfo
