import React from 'react'
import { dataArrayProtectToday } from '../../../API/Data'
import { useNavigate } from 'react-router'
// import { Link } from 'react-router-dom'

function ProtectToday() {
    const navigate = useNavigate()

    return (
        <>
            {/* <Link to="/business">Business</Link> */}
            <div className="container pb-5 ">
                <div className="paper-writing-service d-flex flex-column align-items-center  ">
                    <h1 className=" mt-5 text-center mb-5 fw-bold">What would you like to protect today?</h1>
                    <div className="container">
                        <div className="row justify-content-center align-items-center ">
                            {dataArrayProtectToday.map(item => (
                                <div key={item.id} className="col-md-4 col-lg-4 col-xl-3 col-6  text-center  p-3 "
                                    onClick={() => {
                                        navigate(`/${item.heading}`
                                        // , 
                                        // {
                                        //     state: {
                                        //         title: item.heading,
                                        //         topImg: item.topImg,
                                        //         bottomImg:item.bottomImg
                                        //     },
                                        // }
                                        );
                                        window.scrollTo(0, 0);
                                    }}>
                                    <div className=' card-radius shadow py-3 main-card'>
                                        <div className='d-flex justify-content-center align-items-center flex-column  pe-5 ps-5 py-4'>
                                            <div className='Image-box'>
                                                <img className={item.styleClass} src={item.img} alt={`Image ${item.id}`} />
                                            </div>
                                            <p className='pb-0 mb-0 text-start  txt-14 txt-fw-700'>{item.heading}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProtectToday