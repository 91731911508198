import React from 'react'
import HeaderContact from '../../../components/HeaderContact/HeaderContact'
import InsuranceSave from '../IsuranceSave.js/InsuranceSave'
import FindInsurance from '../FindInsurance/FindInsurance'
import PersonalizedQuotes from '../PersonalizedQuotes/PersonalizedQuotes'
import ProtectToday from '../ProtectToday/ProtectToday'
import WinHandsDown from '../WinHandsDown/WinHandsDown'
import WhyOqvic from '../../../components/WhyOqvic/WhyOqvic'
import SecondHomeSection from '../SecondHomeSection/SecondHomeSection'
import FooterOqvic from '../../../components/FooterOqvic/FooterOqvic'
function MainAppPage() {
  return (

    <>
    <HeaderContact/>
    <InsuranceSave/>
    <FindInsurance/>
    <PersonalizedQuotes/>
    <ProtectToday/>
    <WinHandsDown/>
    <WhyOqvic />
    <SecondHomeSection/>
    <FooterOqvic/>
    </>
  )
}

export default MainAppPage