import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/Style/style.css'
import MainAppPage from './Pages/LandingPages/MainAppPage/MainAppPage'
import { Route, Routes,} from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import ExploringPages from './Pages/ExpploringPages/ExploringPages';
import FloodInfo from './Pages/FloodPages/FloodInfo/FloodInfo';
import Thankyou from './Pages/FloodPages/Thankyou/Thankyou';
import FloodPage from './Pages/FloodPages/FloodPage/FloodPage';
function App(props) {
 
  return (
    <React.StrictMode>
   <BrowserRouter>
   <Routes>
      <Route path="/" element={<MainAppPage/>}/>
      <Route path="/:pageName" element={<ExploringPages/>}/>
      <Route path="/flood-page" element={<FloodPage/>}/>
      <Route path="/flood-info" element={<FloodInfo />} />
      <Route path="/Thankyou" element={<Thankyou />} />

    </Routes>
   </BrowserRouter>
    {/* <MainAppPage/> */}
    </React.StrictMode>
  );
}

export default App;
