import React from 'react'
import InfoBtnLeft from '../../../icon/FloodIcons/InfoBtnLeft'
import InfoBtnRight from '../../../icon/FloodIcons/InfoBtnRight'
import {Card} from 'react-bootstrap'
const BuildingInfo = ({ formData, setFormData, previous, next}) => {
    return (
        <div>
           
           <Card.Body className='d-flex flex-column align-items-center'>
                <Card.Title className='cardTitle '>Building Information</Card.Title>
                <Card.Subtitle className="cardSubTitle ">Is the building over water?</Card.Subtitle>
            </Card.Body>

            <div className="row d-flex justify-content-center row-gap-custom  mb-4">
                <div class="col-md-3 col-6">
                    <label class=" radio-page6">
                        <input
                            type="radio"
                            name="radio1"
                            value="Yes"
                            className='input-page6'
                            checked={formData.buildingOverWater === 'Yes'}
                            onChange={(e) => setFormData({ ...formData, buildingOverWater: e.target.value })}
                        />
                        <span className='span-page6 '> Yes </span>
                    </label>
                </div>
                <div class="col-md-3 col-6">
                    <label class=" radio-page6">
                        <input
                            type="radio"
                            name="radio1"
                            value="No"
                            className='input-page6'
                            checked={formData.buildingOverWater === 'No'}
                            onChange={(e) => setFormData({ ...formData, buildingOverWater: e.target.value })}
                        />
                        <span className='span-page6'> No </span>
                    </label>
                </div>
            </div>

            <p className='cardSubTitle'>Is the building under construction?</p>

            <div className="row d-flex justify-content-center row-gap-custom  mb-4">
                <div class="col-md-3 col-6">
                    <label class=" radio-page6">
                        <input
                            type="radio"
                            name="radio2"
                            value="Yes"
                            className='input-page6'
                            checked={formData.buildingUnderConstruction === 'Yes'}
                            onChange={(e) => setFormData({ ...formData, buildingUnderConstruction: e.target.value })}
                        />
                        <span className='span-page6'> Yes </span>
                    </label>
                </div>
                <div class="col-md-3 col-6">
                    <label class="radio-page6">
                        <input
                            type="radio"
                            name="radio2"
                            value="No"
                            className='input-page6'
                            checked={formData.buildingUnderConstruction === 'No'}
                            onChange={(e) => setFormData({ ...formData, buildingUnderConstruction: e.target.value })}
                        />
                        <span className='span-page6'> No </span>
                    </label>
                </div>
            </div>

            <p className='cardSubTitle'>Is this your primary residnece?</p>

            <div className="row d-flex justify-content-center row-gap-custom mb-4">
                <div class="col-md-3 col-6">
                    <label class=" radio-page6">
                        <input
                            type="radio"
                            name="radio3"
                            value="Yes"
                            className='input-page6'
                            checked={formData.primaryResidence === 'Yes'}
                            onChange={(e) => setFormData({ ...formData, primaryResidence: e.target.value })}
                        />
                        <span className='span-page6 ps-5'> Yes </span>
                    </label>
                </div>
                <div class="col-md-3 col-6">
                    <label class=" radio-page6">
                        <input
                            type="radio"
                            name="radio3"
                            value="No"
                            className='input-page6'
                            checked={formData.primaryResidence === 'No'}
                            onChange={(e) => setFormData({ ...formData, primaryResidence: e.target.value })}
                        />
                        <span className='span-page6 ps-5'> No </span>
                    </label>
                </div>
            </div>
            <div className="d-flex gap-3 justify-content-center mt-4 flex-row-reverse">
          <button onClick={() => next()} className="info-btn-common">
            <InfoBtnRight />
          </button>
          <button onClick={() => previous()} className="info-btn-common">
            <InfoBtnLeft />
          </button>
        </div>
        </div>
    )
}

export default BuildingInfo